.button-special {
  height: 76px;
  position: relative;
  width: 200px;
}

.button-special .overlap {
  position: relative;
}

.button-special .shadow {
  position: absolute;
}

.button-special .content {
  position: absolute;
}

.button-special .label {
  color: var(--white);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  height: 56px;
  left: 26px;
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  position: absolute;
  text-align: center;
  top: 15px;
  width: 130px;
}

.button-special .overlap-group {
  background-image: url(../../../static/img/path.svg);
  background-size: 100% 100%;
  height: 32px;
  left: 156px;
  position: absolute;
  top: 12px;
  width: 32px;
}

.button-special .ic-price-right {
  height: 16px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 16px;
}

.button-special .ic-loading {
  height: 16px;
  left: 8px;
  position: relative;
  top: 8px;
  width: 16px;
}

.button-special .vector {
  position: absolute;
}

.button-special .union {
  height: 11px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 13px;
}

.button-special .div {
  background-color: #ff4659;
  border-radius: 24px;
  filter: blur(20px);
  height: 38px;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 164px;
}

.button-special .content-2 {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%);
  border-radius: 28px;
  height: 56px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 200px;
}

.button-special .ic-price-right-wrapper {
  background-image: url(../../../static/img/path-7.svg);
  background-size: 100% 100%;
  height: 32px;
  left: 156px;
  position: absolute;
  top: 12px;
  width: 32px;
}

.button-special .ic-price-right-2 {
  height: 16px;
  left: 8px;
  overflow: hidden;
  position: relative;
  top: 8px;
  width: 16px;
}

.button-special .img {
  height: 16px;
  left: 3283px;
  position: absolute;
  top: 523px;
  width: 16px;
}

.button-special .disabled {
  background-color: var(--basecommoncommon-2);
  border-radius: 32px;
  height: 56px;
  overflow: hidden;
}

.button-special .hover {
  height: 57px;
}

.button-special .loading {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%);
  border-radius: 28px;
  height: 56px;
  overflow: hidden;
}

.button-special .normal {
  height: 57px;
}

.button-special .overlap.disabled .shadow {
  color: var(--darkdark-1);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  height: 56px;
  left: 26px;
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  text-align: center;
  top: -1px;
  width: 130px;
}

.button-special .overlap.hover .shadow {
  height: 56px;
  left: 26px;
  top: -1px;
  width: 130px;
}

.button-special .overlap.loading .shadow {
  color: var(--white);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  height: 56px;
  left: 26px;
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  text-align: center;
  top: -1px;
  width: 130px;
}

.button-special .overlap.normal .shadow {
  background-color: #ff4659;
  border-radius: 24px;
  filter: blur(20px);
  height: 38px;
  left: 19px;
  top: 19px;
  width: 164px;
}

.button-special .overlap.disabled .content {
  background-image: url(../../../static/img/path-5.svg);
  background-size: 100% 100%;
  height: 32px;
  left: 156px;
  top: 12px;
  width: 32px;
}

.button-special .overlap.hover .content {
  height: 32px;
  left: 156px;
  top: 12px;
  width: 32px;
}

.button-special .overlap.loading .content {
  background-image: url(../../../static/img/path-6.svg);
  background-size: 100% 100%;
  height: 32px;
  left: 156px;
  top: 12px;
  width: 32px;
}

.button-special .overlap.normal .content {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%);
  border-radius: 28px;
  height: 56px;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 200px;
}

.button-special .overlap.disabled .ic-loading {
  overflow: hidden;
}

.button-special .overlap.disabled .vector {
  height: 16px;
  left: 3283px;
  top: 331px;
  width: 16px;
}

.button-special .overlap.hover .vector {
  height: 16px;
  left: 3283px;
  top: 331px;
  width: 16px;
}

.button-special .overlap.loading .vector {
  height: 14px;
  left: 1px;
  top: 1px;
  width: 14px;
}

.button-special .overlap.normal .vector {
  height: 16px;
  left: 3283px;
  top: 331px;
  width: 16px;
}