:root {
  --10-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --10-open-sans-normal-font-size: 10px;
  --10-open-sans-normal-font-style: normal;
  --10-open-sans-normal-font-weight: 400;
  --10-open-sans-normal-letter-spacing: 0px;
  --10-open-sans-normal-line-height: 12px;
  --10-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --10-open-sans-semibold-font-size: 10px;
  --10-open-sans-semibold-font-style: normal;
  --10-open-sans-semibold-font-weight: 600;
  --10-open-sans-semibold-letter-spacing: 0px;
  --10-open-sans-semibold-line-height: 12px;
  --12-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --12-open-sans-normal-font-size: 12px;
  --12-open-sans-normal-font-style: normal;
  --12-open-sans-normal-font-weight: 400;
  --12-open-sans-normal-letter-spacing: 0px;
  --12-open-sans-normal-line-height: 16px;
  --12-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --12-open-sans-semibold-font-size: 12px;
  --12-open-sans-semibold-font-style: normal;
  --12-open-sans-semibold-font-weight: 600;
  --12-open-sans-semibold-letter-spacing: 0px;
  --12-open-sans-semibold-line-height: 16px;
  --14-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --14-open-sans-normal-font-size: 14px;
  --14-open-sans-normal-font-style: normal;
  --14-open-sans-normal-font-weight: 400;
  --14-open-sans-normal-letter-spacing: 0px;
  --14-open-sans-normal-line-height: 20px;
  --14-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --14-open-sans-semibold-font-size: 14px;
  --14-open-sans-semibold-font-style: normal;
  --14-open-sans-semibold-font-weight: 600;
  --14-open-sans-semibold-letter-spacing: 0px;
  --14-open-sans-semibold-line-height: 20px;
  --16-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --16-open-sans-normal-font-size: 16px;
  --16-open-sans-normal-font-style: normal;
  --16-open-sans-normal-font-weight: 400;
  --16-open-sans-normal-letter-spacing: 0px;
  --16-open-sans-normal-line-height: 24px;
  --16-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --16-open-sans-semibold-font-size: 16px;
  --16-open-sans-semibold-font-style: normal;
  --16-open-sans-semibold-font-weight: 600;
  --16-open-sans-semibold-letter-spacing: 0px;
  --16-open-sans-semibold-line-height: 24px;
  --18-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --18-open-sans-normal-font-size: 18px;
  --18-open-sans-normal-font-style: normal;
  --18-open-sans-normal-font-weight: 400;
  --18-open-sans-normal-letter-spacing: 0px;
  --18-open-sans-normal-line-height: 26px;
  --18-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --18-open-sans-semibold-font-size: 18px;
  --18-open-sans-semibold-font-style: normal;
  --18-open-sans-semibold-font-weight: 600;
  --18-open-sans-semibold-letter-spacing: 0px;
  --18-open-sans-semibold-line-height: 26px;
  --20-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --20-open-sans-normal-font-size: 20px;
  --20-open-sans-normal-font-style: normal;
  --20-open-sans-normal-font-weight: 400;
  --20-open-sans-normal-letter-spacing: 0px;
  --20-open-sans-normal-line-height: 28px;
  --20-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --20-open-sans-semibold-font-size: 20px;
  --20-open-sans-semibold-font-style: normal;
  --20-open-sans-semibold-font-weight: 600;
  --20-open-sans-semibold-letter-spacing: 0px;
  --20-open-sans-semibold-line-height: 28px;
  --24-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --24-open-sans-normal-font-size: 24px;
  --24-open-sans-normal-font-style: normal;
  --24-open-sans-normal-font-weight: 400;
  --24-open-sans-normal-letter-spacing: 0px;
  --24-open-sans-normal-line-height: 32px;
  --24-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --24-open-sans-semibold-font-size: 24px;
  --24-open-sans-semibold-font-style: normal;
  --24-open-sans-semibold-font-weight: 600;
  --24-open-sans-semibold-letter-spacing: 0px;
  --24-open-sans-semibold-line-height: 32px;
  --32-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --32-open-sans-normal-font-size: 32px;
  --32-open-sans-normal-font-style: normal;
  --32-open-sans-normal-font-weight: 400;
  --32-open-sans-normal-letter-spacing: 0px;
  --32-open-sans-normal-line-height: 40px;
  --32-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --32-open-sans-semibold-font-size: 32px;
  --32-open-sans-semibold-font-style: normal;
  --32-open-sans-semibold-font-weight: 600;
  --32-open-sans-semibold-letter-spacing: 0px;
  --32-open-sans-semibold-line-height: 40px;
  --40-open-sans-bold-font-family: "OpenSans-Bold", Helvetica;
  --40-open-sans-bold-font-size: 40px;
  --40-open-sans-bold-font-style: normal;
  --40-open-sans-bold-font-weight: 700;
  --40-open-sans-bold-letter-spacing: 0px;
  --40-open-sans-bold-line-height: 48px;
  --40-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --40-open-sans-normal-font-size: 40px;
  --40-open-sans-normal-font-style: normal;
  --40-open-sans-normal-font-weight: 400;
  --40-open-sans-normal-letter-spacing: 0px;
  --40-open-sans-normal-line-height: 48px;
  --48-open-sans-bold-font-family: "OpenSans-Bold", Helvetica;
  --48-open-sans-bold-font-size: 48px;
  --48-open-sans-bold-font-style: normal;
  --48-open-sans-bold-font-weight: 700;
  --48-open-sans-bold-letter-spacing: 0px;
  --48-open-sans-bold-line-height: 56px;
  --48-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --48-open-sans-normal-font-size: 48px;
  --48-open-sans-normal-font-style: normal;
  --48-open-sans-normal-font-weight: 400;
  --48-open-sans-normal-letter-spacing: 0px;
  --48-open-sans-normal-line-height: 56px;
  --60-open-sans-bold-font-family: "OpenSans-Bold", Helvetica;
  --60-open-sans-bold-font-size: 60px;
  --60-open-sans-bold-font-style: normal;
  --60-open-sans-bold-font-weight: 700;
  --60-open-sans-bold-letter-spacing: 0px;
  --60-open-sans-bold-line-height: 72px;
  --60-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --60-open-sans-normal-font-size: 60px;
  --60-open-sans-normal-font-style: normal;
  --60-open-sans-normal-font-weight: 400;
  --60-open-sans-normal-letter-spacing: 0px;
  --60-open-sans-normal-line-height: 72px;
  --basecommoncommon-2: #cbcccf;
  --basicwhite: #fff;
  --companian-2: #4764fa;
  --darkdark-1: #e9e9ea;
  --darkdark-10: #111115;
  --darkdark-2: #cbcbcd;
  --darkdark-3: #a2a2a6;
  --darkdark-4: #76767c;
  --darkdark-5: #4d4d54;
  --darkdark-6: #26262f;
  --darkdark-7: #202028;
  --darkdark-8: #1b1b21;
  --darkdark-9: #16161b;
  --lookonlookon-primary: #4779fa;
  --mon-h6-20-mon-s-font-family: "Montserrat-SemiBold", Helvetica;
  --mon-h6-20-mon-s-font-size: 20px;
  --mon-h6-20-mon-s-font-style: normal;
  --mon-h6-20-mon-s-font-weight: 600;
  --mon-h6-20-mon-s-letter-spacing: 0px;
  --mon-h6-20-mon-s-line-height: 28px;
  --statusnegative-1: #fcedef;
  --statusnegative-10: #631f29;
  --statusnegative-2: #f7d3d8;
  --statusnegative-3: #f0afb8;
  --statusnegative-4: #ea8a98;
  --statusnegative-5: #e36779;
  --statusnegative-6: #dd465b;
  --statusnegative-7: #bc3c4d;
  --statusnegative-8: #9d3241;
  --statusnegative-9: #7e2834;
  --statuspositive-1: #f4faed;
  --statuspositive-10: #415c20;
  --statuspositive-2: #e5f3d3;
  --statuspositive-3: #d0e9b0;
  --statuspositive-4: #badf8b;
  --statuspositive-5: #a5d568;
  --statuspositive-6: #91cc47;
  --statuspositive-7: #7bad3c;
  --statuspositive-8: #679132;
  --statuspositive-9: #537428;
  --statuswarning-1: #fbf8ec;
  --statuswarning-10: #63531c;
  --statuswarning-2: #f6eed1;
  --statuswarning-3: #f0e1ac;
  --statuswarning-4: #e8d386;
  --statuswarning-5: #e1c662;
  --statuswarning-6: #dbb93f;
  --statuswarning-7: #ba9d36;
  --statuswarning-8: #9b832d;
  --statuswarning-9: #7d6924;
  --supportsupport-1: #f5f7f9;
  --supportsupport-10: #464d5a;
  --supportsupport-2: #e7ebf2;
  --supportsupport-3: #d4dbe7;
  --supportsupport-4: #c0cadc;
  --supportsupport-5: #adbad1;
  --supportsupport-6: #9babc7;
  --supportsupport-7: #8491a9;
  --supportsupport-8: #6e798d;
  --supportsupport-9: #586171;
  --variable-collection-companians-solid: #ff495f;
  --white: #fff;
}

.button-special {
  width: 200px;
  height: 76px;
  position: relative;
}

.button-special .overlap {
  position: relative;
}

.button-special .shadow, .button-special .content {
  position: absolute;
}

.button-special .label {
  color: var(--white);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  text-align: center;
  width: 130px;
  height: 56px;
  position: absolute;
  top: 15px;
  left: 26px;
}

.button-special .overlap-group {
  background-image: url("path.998110c1.svg");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 12px;
  left: 156px;
}

.button-special .ic-price-right {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.button-special .ic-loading {
  width: 16px;
  height: 16px;
  position: relative;
  top: 8px;
  left: 8px;
}

.button-special .vector {
  position: absolute;
}

.button-special .union {
  width: 13px;
  height: 11px;
  position: absolute;
  top: 3px;
  left: 1px;
}

.button-special .div {
  filter: blur(20px);
  background-color: #ff4659;
  border-radius: 24px;
  width: 164px;
  height: 38px;
  position: absolute;
  top: 19px;
  left: 19px;
}

.button-special .content-2 {
  background: linear-gradient(#ff4454 0%, #ff5170 100%);
  border-radius: 28px;
  width: 200px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.button-special .ic-price-right-wrapper {
  background-image: url("path-7.d744cc19.svg");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 12px;
  left: 156px;
}

.button-special .ic-price-right-2 {
  width: 16px;
  height: 16px;
  position: relative;
  top: 8px;
  left: 8px;
  overflow: hidden;
}

.button-special .img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 523px;
  left: 3283px;
}

.button-special .disabled {
  background-color: var(--basecommoncommon-2);
  border-radius: 32px;
  height: 56px;
  overflow: hidden;
}

.button-special .hover {
  height: 57px;
}

.button-special .loading {
  background: linear-gradient(#ff4454 0%, #ff5170 100%);
  border-radius: 28px;
  height: 56px;
  overflow: hidden;
}

.button-special .normal {
  height: 57px;
}

.button-special .overlap.disabled .shadow {
  color: var(--darkdark-1);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  text-align: center;
  width: 130px;
  height: 56px;
  top: -1px;
  left: 26px;
}

.button-special .overlap.hover .shadow {
  width: 130px;
  height: 56px;
  top: -1px;
  left: 26px;
}

.button-special .overlap.loading .shadow {
  color: var(--white);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  text-align: center;
  width: 130px;
  height: 56px;
  top: -1px;
  left: 26px;
}

.button-special .overlap.normal .shadow {
  filter: blur(20px);
  background-color: #ff4659;
  border-radius: 24px;
  width: 164px;
  height: 38px;
  top: 19px;
  left: 19px;
}

.button-special .overlap.disabled .content {
  background-image: url("path-5.f7c24415.svg");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  top: 12px;
  left: 156px;
}

.button-special .overlap.hover .content {
  width: 32px;
  height: 32px;
  top: 12px;
  left: 156px;
}

.button-special .overlap.loading .content {
  background-image: url("path-6.53acd281.svg");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  top: 12px;
  left: 156px;
}

.button-special .overlap.normal .content {
  background: linear-gradient(#ff4454 0%, #ff5170 100%);
  border-radius: 28px;
  width: 200px;
  height: 56px;
  top: 0;
  left: 0;
  overflow: hidden;
}

.button-special .overlap.disabled .ic-loading {
  overflow: hidden;
}

.button-special .overlap.disabled .vector, .button-special .overlap.hover .vector {
  width: 16px;
  height: 16px;
  top: 331px;
  left: 3283px;
}

.button-special .overlap.loading .vector {
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
}

.button-special .overlap.normal .vector {
  width: 16px;
  height: 16px;
  top: 331px;
  left: 3283px;
}

.divider {
  background-color: var(--darkdark-6);
  border-radius: 2px;
  width: 80px;
  height: 1px;
}

.ic-account {
  background-image: url("ic-24-account-2.90b6cc11.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.ic-arrow-down {
  background-image: url("ic-16-arrow-down-9.67465248.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.ic-arrow-down-wrapper {
  background-image: url("ic-16-arrow-down-7.c95d8971.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.type-gradient-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.type-gradient-wrapper.line {
  object-fit: cover;
  width: 724px;
  height: 1px;
}

.type-gradient-wrapper.gradient {
  width: 723px;
  height: 16px;
}

.companians-landing {
  background-color: var(--white);
  border-radius: 16px;
  width: 1600px;
  height: 2639px;
  position: relative;
  overflow: hidden;
}

.companians-landing .h-ng-l-n-mu-n-i-ch-i {
  color: var(--darkdark-10);
  letter-spacing: 0;
  width: 560px;
  font-family: Montserrat, Helvetica;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  position: absolute;
  top: 159px;
  left: 184px;
}

.companians-landing .text-wrapper {
  color: var(--darkdark-10);
  letter-spacing: 0;
  text-align: center;
  width: 560px;
  font-family: Montserrat, Helvetica;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  position: absolute;
  top: 713px;
  left: 520px;
}

.companians-landing .group {
  width: 815px;
  height: 1060px;
  position: absolute;
  top: 814px;
  left: 392px;
}

.companians-landing .group-2 {
  width: 835px;
  height: 1060px;
  position: relative;
}

.companians-landing .p {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 747px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 7px;
  left: 0;
}

.companians-landing .divider-instance {
  background: linear-gradient(#ff4454 0%, #ff5170 100%) !important;
  background-color: unset !important;
  opacity: .2 !important;
  width: 815px !important;
  position: absolute !important;
  top: 79px !important;
  left: 0 !important;
}

.companians-landing .text-wrapper-2 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 747px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 120px;
  left: 0;
}

.companians-landing .design-component-instance-node {
  background: linear-gradient(#ff4454 0%, #ff5170 100%) !important;
  background-color: unset !important;
  opacity: .2 !important;
  width: 815px !important;
  position: absolute !important;
  top: 192px !important;
  left: 0 !important;
}

.companians-landing .group-wrapper {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 767px;
}

.companians-landing .ic-arrow-down-instance-wrapper {
  background-color: var(--supportsupport-1);
  border: 1px solid;
  border-color: var(--supportsupport-2);
  border-radius: 20px;
  height: 48px;
}

.companians-landing .ic-arrow-down-instance {
  background-image: url("ic-16-arrow-down-5.3f693660.svg") !important;
  position: relative !important;
  top: 11px !important;
  left: 11px !important;
}

.companians-landing .div-wrapper {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 112px;
  left: 767px;
}

.companians-landing .ic-arrow-down-2 {
  background-image: url("ic-16-arrow-down-4.aa65ecf5.svg") !important;
  position: relative !important;
  top: 11px !important;
  left: 11px !important;
}

.companians-landing .divider-2 {
  background: linear-gradient(#ff4454 0%, #ff5170 100%) !important;
  background-color: unset !important;
  opacity: .2 !important;
  width: 815px !important;
  position: absolute !important;
  top: 640px !important;
  left: 0 !important;
}

.companians-landing .element-t-i-l-m-th-n-o-n-u {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 747px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 681px;
  left: 0;
}

.companians-landing .group-3 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 673px;
  left: 767px;
}

.companians-landing .ic-arrow-down-3 {
  background-image: url("ic-16-arrow-down-3.35608ebe.svg") !important;
  position: relative !important;
  top: 11px !important;
  left: 11px !important;
}

.companians-landing .divider-3 {
  background: linear-gradient(#ff4454 0%, #ff5170 100%) !important;
  background-color: unset !important;
  opacity: .2 !important;
  width: 815px !important;
  position: absolute !important;
  top: 429px !important;
  left: 0 !important;
}

.companians-landing .text-wrapper-3 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 747px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 470px;
  left: 0;
}

.companians-landing .group-4 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 462px;
  left: 767px;
}

.companians-landing .ic-arrow-down-4 {
  background-image: url("ic-16-arrow-down-2.182fb334.svg") !important;
  position: relative !important;
  top: 11px !important;
  left: 11px !important;
}

.companians-landing .text-wrapper-4 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 747px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 233px;
  left: 0;
}

.companians-landing .group-5 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 225px;
  left: 767px;
}

.companians-landing .ic-arrow-down-5 {
  background-image: url("ic-16-arrow-down-1.5fdf1250.svg") !important;
  position: relative !important;
  top: 11px !important;
  left: 11px !important;
}

.companians-landing .text-wrapper-5 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 746px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  position: absolute;
  top: 285px;
  left: 0;
}

.companians-landing .b-n-t-i-c-th-l-m-g-n {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 746px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  position: absolute;
  top: 522px;
  left: 0;
}

.companians-landing .text-wrapper-6 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 746px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  position: absolute;
  top: 765px;
  left: 0;
}

.companians-landing .divider-4 {
  background: linear-gradient(#ff4454 0%, #ff5170 100%) !important;
  background-color: unset !important;
  opacity: .2 !important;
  width: 815px !important;
  position: absolute !important;
  top: 883px !important;
  left: 0 !important;
}

.companians-landing .text-wrapper-7 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 747px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 924px;
  left: 0;
}

.companians-landing .group-6 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 916px;
  left: 767px;
}

.companians-landing .ic-arrow-down-6 {
  background-image: url("ic-16-arrow-down.236b2394.svg") !important;
  position: relative !important;
  top: 11px !important;
  left: 11px !important;
}

.companians-landing .text-wrapper-8 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 746px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  position: absolute;
  top: 1008px;
  left: 0;
}

.companians-landing .text-wrapper-9 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(#ff4454 0%, #ff5170 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 560px;
  font-family: Montserrat, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  position: absolute;
  top: 283px;
  left: 184px;
  -webkit-background-clip: text !important;
}

.companians-landing .text-wrapper-10 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  width: 560px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  position: absolute;
  top: 367px;
  left: 184px;
}

.companians-landing .group-7 {
  width: 672px;
  height: 414px;
  position: absolute;
  top: 160px;
  left: 896px;
}

.companians-landing .companians-menu {
  background-color: var(--basicwhite);
  width: 1600px;
  height: 84px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.companians-landing .overlap-2 {
  height: 76px;
  position: relative;
  top: 12px;
}

.companians-landing .text-wrapper-11 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  font-family: var(--mon-h6-20-mon-s-font-family);
  font-size: var(--mon-h6-20-mon-s-font-size);
  font-style: var(--mon-h6-20-mon-s-font-style);
  font-weight: var(--mon-h6-20-mon-s-font-weight);
  letter-spacing: var(--mon-h6-20-mon-s-letter-spacing);
  line-height: var(--mon-h6-20-mon-s-line-height);
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#ff4454 0%, #ff5170 100%);
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  top: 13px;
  left: 87px;
  -webkit-background-clip: text !important;
}

.companians-landing .overlap-3 {
  width: 1600px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 0;
}

.companians-landing .divider-5 {
  width: 1600px !important;
  top: 68px !important;
}

.companians-landing .button-special-instance {
  width: 228px !important;
  position: absolute !important;
  top: 0 !important;
  left: 1340px !important;
}

.companians-landing .button-special-2 {
  width: 192px !important;
}

.companians-landing .button-special-3 {
  width: 228px !important;
}

.companians-landing .button-special-4 {
  width: 158px !important;
}

.companians-landing .button-special-5 {
  background-image: url("path-3.c84c61f0.svg") !important;
  left: 184px !important;
}

.companians-landing .frame {
  align-items: flex-start;
  gap: 59px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 620px;
}

.companians-landing .text-wrapper-12 {
  color: var(--darkdark-6);
  font-family: var(--16-open-sans-normal-font-family);
  font-size: var(--16-open-sans-normal-font-size);
  font-style: var(--16-open-sans-normal-font-style);
  font-weight: var(--16-open-sans-normal-font-weight);
  letter-spacing: var(--16-open-sans-normal-letter-spacing);
  line-height: var(--16-open-sans-normal-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.companians-landing .frame-2 {
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 1230px;
}

.companians-landing .ic-account-instance {
  background-image: url("ic-24-account.2d9e3649.svg") !important;
  position: relative !important;
}

.companians-landing .text-wrapper-13 {
  color: var(--darkdark-6);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.companians-landing .champagne-cheers {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 8px;
  left: 35px;
}

.companians-landing .button-special-6 {
  width: 228px !important;
  position: absolute !important;
  top: 486px !important;
  left: 184px !important;
}

.companians-landing .button-special-7 {
  background-image: url("path-2.7e9ff7a8.svg") !important;
  left: 184px !important;
}

.companians-landing .button-special-8 {
  width: 228px !important;
  position: absolute !important;
  top: 2374px !important;
  left: 686px !important;
}

.companians-landing .button-special-9 {
  background-image: url("path-1.d39d3231.svg") !important;
  left: 184px !important;
}

.companians-landing .image {
  width: 327px;
  height: 208px;
  position: absolute;
  top: 2003px;
  left: 257px;
}

.companians-landing .image-2 {
  width: 327px;
  height: 208px;
  position: absolute;
  top: 2005px;
  left: 1016px;
}

.companians-landing .text-wrapper-14 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 2229px;
  left: 257px;
}

.companians-landing .text-wrapper-15 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 2229px;
  left: 1016px;
}

.companians-landing .group-8 {
  width: 329px;
  height: 289px;
  position: absolute;
  top: 2005px;
  left: 636px;
}

.companians-landing .image-3 {
  width: 327px;
  height: 208px;
  position: absolute;
  top: 0;
  left: 0;
}

.companians-landing .text-wrapper-16 {
  color: var(--darkdark-6);
  letter-spacing: 0;
  text-align: center;
  width: 327px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  position: absolute;
  top: 225px;
  left: 0;
}

/*# sourceMappingURL=index.872af6e1.css.map */
