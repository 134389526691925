.companians-landing {
  background-color: var(--white);
  border-radius: 16px;
  height: 2639px;
  overflow: hidden;
  position: relative;
  width: 1600px;
}

.companians-landing .h-ng-l-n-mu-n-i-ch-i {
  color: var(--darkdark-10);
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 600;
  left: 184px;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  top: 159px;
  width: 560px;
}

.companians-landing .text-wrapper {
  color: var(--darkdark-10);
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 600;
  left: 520px;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: 713px;
  width: 560px;
}

.companians-landing .group {
  height: 1060px;
  left: 392px;
  position: absolute;
  top: 814px;
  width: 815px;
}

.companians-landing .group-2 {
  height: 1060px;
  position: relative;
  width: 835px;
}

.companians-landing .p {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 7px;
  width: 747px;
}

.companians-landing .divider-instance {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%) !important;
  background-color: unset !important;
  left: 0 !important;
  opacity: 0.2 !important;
  position: absolute !important;
  top: 79px !important;
  width: 815px !important;
}

.companians-landing .text-wrapper-2 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 120px;
  width: 747px;
}

.companians-landing .design-component-instance-node {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%) !important;
  background-color: unset !important;
  left: 0 !important;
  opacity: 0.2 !important;
  position: absolute !important;
  top: 192px !important;
  width: 815px !important;
}

.companians-landing .group-wrapper {
  height: 48px;
  left: 767px;
  position: absolute;
  top: 0;
  width: 48px;
}

.companians-landing .ic-arrow-down-instance-wrapper {
  background-color: var(--supportsupport-1);
  border: 1px solid;
  border-color: var(--supportsupport-2);
  border-radius: 20px;
  height: 48px;
}

.companians-landing .ic-arrow-down-instance {
  background-image: url(../../../static/img/ic-16-arrow-down-5.svg) !important;
  left: 11px !important;
  position: relative !important;
  top: 11px !important;
}

.companians-landing .div-wrapper {
  height: 48px;
  left: 767px;
  position: absolute;
  top: 112px;
  width: 48px;
}

.companians-landing .ic-arrow-down-2 {
  background-image: url(../../../static/img/ic-16-arrow-down-4.svg) !important;
  left: 11px !important;
  position: relative !important;
  top: 11px !important;
}

.companians-landing .divider-2 {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%) !important;
  background-color: unset !important;
  left: 0 !important;
  opacity: 0.2 !important;
  position: absolute !important;
  top: 640px !important;
  width: 815px !important;
}

.companians-landing .element-t-i-l-m-th-n-o-n-u {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 681px;
  width: 747px;
}

.companians-landing .group-3 {
  height: 48px;
  left: 767px;
  position: absolute;
  top: 673px;
  width: 48px;
}

.companians-landing .ic-arrow-down-3 {
  background-image: url(../../../static/img/ic-16-arrow-down-3.svg) !important;
  left: 11px !important;
  position: relative !important;
  top: 11px !important;
}

.companians-landing .divider-3 {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%) !important;
  background-color: unset !important;
  left: 0 !important;
  opacity: 0.2 !important;
  position: absolute !important;
  top: 429px !important;
  width: 815px !important;
}

.companians-landing .text-wrapper-3 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 470px;
  width: 747px;
}

.companians-landing .group-4 {
  height: 48px;
  left: 767px;
  position: absolute;
  top: 462px;
  width: 48px;
}

.companians-landing .ic-arrow-down-4 {
  background-image: url(../../../static/img/ic-16-arrow-down-2.svg) !important;
  left: 11px !important;
  position: relative !important;
  top: 11px !important;
}

.companians-landing .text-wrapper-4 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 233px;
  width: 747px;
}

.companians-landing .group-5 {
  height: 48px;
  left: 767px;
  position: absolute;
  top: 225px;
  width: 48px;
}

.companians-landing .ic-arrow-down-5 {
  background-image: url(../../../static/img/ic-16-arrow-down-1.svg) !important;
  left: 11px !important;
  position: relative !important;
  top: 11px !important;
}

.companians-landing .text-wrapper-5 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 285px;
  width: 746px;
}

.companians-landing .b-n-t-i-c-th-l-m-g-n {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 522px;
  width: 746px;
}

.companians-landing .text-wrapper-6 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 765px;
  width: 746px;
}

.companians-landing .divider-4 {
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%) !important;
  background-color: unset !important;
  left: 0 !important;
  opacity: 0.2 !important;
  position: absolute !important;
  top: 883px !important;
  width: 815px !important;
}

.companians-landing .text-wrapper-7 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 924px;
  width: 747px;
}

.companians-landing .group-6 {
  height: 48px;
  left: 767px;
  position: absolute;
  top: 916px;
  width: 48px;
}

.companians-landing .ic-arrow-down-6 {
  background-image: url(../../../static/img/ic-16-arrow-down.svg) !important;
  left: 11px !important;
  position: relative !important;
  top: 11px !important;
}

.companians-landing .text-wrapper-8 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 1008px;
  width: 746px;
}

.companians-landing .text-wrapper-9 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 184px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  text-fill-color: transparent;
  top: 283px;
  width: 560px;
}

.companians-landing .text-wrapper-10 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 184px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 367px;
  width: 560px;
}

.companians-landing .group-7 {
  height: 414px;
  left: 896px;
  position: absolute;
  top: 160px;
  width: 672px;
}

.companians-landing .companians-menu {
  background-color: var(--basicwhite);
  height: 84px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1600px;
}

.companians-landing .overlap-2 {
  height: 76px;
  position: relative;
  top: 12px;
}

.companians-landing .text-wrapper-11 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(255, 68, 84.36) 0%, rgb(255, 80.75, 112.11) 100%);
  background-clip: text;
  color: transparent;
  font-family: var(--mon-h6-20-mon-s-font-family);
  font-size: var(--mon-h6-20-mon-s-font-size);
  font-style: var(--mon-h6-20-mon-s-font-style);
  font-weight: var(--mon-h6-20-mon-s-font-weight);
  left: 87px;
  letter-spacing: var(--mon-h6-20-mon-s-letter-spacing);
  line-height: var(--mon-h6-20-mon-s-line-height);
  position: absolute;
  text-fill-color: transparent;
  top: 13px;
  white-space: nowrap;
}

.companians-landing .overlap-3 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1600px;
}

.companians-landing .divider-5 {
  top: 68px !important;
  width: 1600px !important;
}

.companians-landing .button-special-instance {
  left: 1340px !important;
  position: absolute !important;
  top: 0 !important;
  width: 228px !important;
}

.companians-landing .button-special-2 {
  width: 192px !important;
}

.companians-landing .button-special-3 {
  width: 228px !important;
}

.companians-landing .button-special-4 {
  width: 158px !important;
}

.companians-landing .button-special-5 {
  background-image: url(../../../static/img/path-3.svg) !important;
  left: 184px !important;
}

.companians-landing .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 59px;
  left: 620px;
  position: absolute;
  top: 16px;
}

.companians-landing .text-wrapper-12 {
  color: var(--darkdark-6);
  font-family: var(--16-open-sans-normal-font-family);
  font-size: var(--16-open-sans-normal-font-size);
  font-style: var(--16-open-sans-normal-font-style);
  font-weight: var(--16-open-sans-normal-font-weight);
  letter-spacing: var(--16-open-sans-normal-letter-spacing);
  line-height: var(--16-open-sans-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.companians-landing .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  gap: 4px;
  left: 1230px;
  position: absolute;
  top: 16px;
}

.companians-landing .ic-account-instance {
  background-image: url(../../../static/img/ic-24-account.svg) !important;
  position: relative !important;
}

.companians-landing .text-wrapper-13 {
  color: var(--darkdark-6);
  font-family: var(--16-open-sans-semibold-font-family);
  font-size: var(--16-open-sans-semibold-font-size);
  font-style: var(--16-open-sans-semibold-font-style);
  font-weight: var(--16-open-sans-semibold-font-weight);
  letter-spacing: var(--16-open-sans-semibold-letter-spacing);
  line-height: var(--16-open-sans-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.companians-landing .champagne-cheers {
  height: 40px;
  left: 35px;
  position: absolute;
  top: 8px;
  width: 40px;
}

.companians-landing .button-special-6 {
  left: 184px !important;
  position: absolute !important;
  top: 486px !important;
  width: 228px !important;
}

.companians-landing .button-special-7 {
  background-image: url(../../../static/img/path-2.svg) !important;
  left: 184px !important;
}

.companians-landing .button-special-8 {
  left: 686px !important;
  position: absolute !important;
  top: 2374px !important;
  width: 228px !important;
}

.companians-landing .button-special-9 {
  background-image: url(../../../static/img/path-1.svg) !important;
  left: 184px !important;
}

.companians-landing .image {
  height: 208px;
  left: 257px;
  position: absolute;
  top: 2003px;
  width: 327px;
}

.companians-landing .image-2 {
  height: 208px;
  left: 1016px;
  position: absolute;
  top: 2005px;
  width: 327px;
}

.companians-landing .text-wrapper-14 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 257px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 2229px;
  width: 327px;
}

.companians-landing .text-wrapper-15 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 1016px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 2229px;
  width: 327px;
}

.companians-landing .group-8 {
  height: 289px;
  left: 636px;
  position: absolute;
  top: 2005px;
  width: 329px;
}

.companians-landing .image-3 {
  height: 208px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.companians-landing .text-wrapper-16 {
  color: var(--darkdark-6);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 225px;
  width: 327px;
}
