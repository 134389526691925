.type-gradient-wrapper {
  left: 0;
  position: absolute;
  top: 0;
}

.type-gradient-wrapper.line {
  height: 1px;
  object-fit: cover;
  width: 724px;
}

.type-gradient-wrapper.gradient {
  height: 16px;
  width: 723px;
}
