:root {
  --10-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --10-open-sans-normal-font-size: 10px;
  --10-open-sans-normal-font-style: normal;
  --10-open-sans-normal-font-weight: 400;
  --10-open-sans-normal-letter-spacing: 0px;
  --10-open-sans-normal-line-height: 12px;
  --10-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --10-open-sans-semibold-font-size: 10px;
  --10-open-sans-semibold-font-style: normal;
  --10-open-sans-semibold-font-weight: 600;
  --10-open-sans-semibold-letter-spacing: 0px;
  --10-open-sans-semibold-line-height: 12px;
  --12-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --12-open-sans-normal-font-size: 12px;
  --12-open-sans-normal-font-style: normal;
  --12-open-sans-normal-font-weight: 400;
  --12-open-sans-normal-letter-spacing: 0px;
  --12-open-sans-normal-line-height: 16px;
  --12-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --12-open-sans-semibold-font-size: 12px;
  --12-open-sans-semibold-font-style: normal;
  --12-open-sans-semibold-font-weight: 600;
  --12-open-sans-semibold-letter-spacing: 0px;
  --12-open-sans-semibold-line-height: 16px;
  --14-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --14-open-sans-normal-font-size: 14px;
  --14-open-sans-normal-font-style: normal;
  --14-open-sans-normal-font-weight: 400;
  --14-open-sans-normal-letter-spacing: 0px;
  --14-open-sans-normal-line-height: 20px;
  --14-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --14-open-sans-semibold-font-size: 14px;
  --14-open-sans-semibold-font-style: normal;
  --14-open-sans-semibold-font-weight: 600;
  --14-open-sans-semibold-letter-spacing: 0px;
  --14-open-sans-semibold-line-height: 20px;
  --16-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --16-open-sans-normal-font-size: 16px;
  --16-open-sans-normal-font-style: normal;
  --16-open-sans-normal-font-weight: 400;
  --16-open-sans-normal-letter-spacing: 0px;
  --16-open-sans-normal-line-height: 24px;
  --16-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --16-open-sans-semibold-font-size: 16px;
  --16-open-sans-semibold-font-style: normal;
  --16-open-sans-semibold-font-weight: 600;
  --16-open-sans-semibold-letter-spacing: 0px;
  --16-open-sans-semibold-line-height: 24px;
  --18-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --18-open-sans-normal-font-size: 18px;
  --18-open-sans-normal-font-style: normal;
  --18-open-sans-normal-font-weight: 400;
  --18-open-sans-normal-letter-spacing: 0px;
  --18-open-sans-normal-line-height: 26px;
  --18-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --18-open-sans-semibold-font-size: 18px;
  --18-open-sans-semibold-font-style: normal;
  --18-open-sans-semibold-font-weight: 600;
  --18-open-sans-semibold-letter-spacing: 0px;
  --18-open-sans-semibold-line-height: 26px;
  --20-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --20-open-sans-normal-font-size: 20px;
  --20-open-sans-normal-font-style: normal;
  --20-open-sans-normal-font-weight: 400;
  --20-open-sans-normal-letter-spacing: 0px;
  --20-open-sans-normal-line-height: 28px;
  --20-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --20-open-sans-semibold-font-size: 20px;
  --20-open-sans-semibold-font-style: normal;
  --20-open-sans-semibold-font-weight: 600;
  --20-open-sans-semibold-letter-spacing: 0px;
  --20-open-sans-semibold-line-height: 28px;
  --24-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --24-open-sans-normal-font-size: 24px;
  --24-open-sans-normal-font-style: normal;
  --24-open-sans-normal-font-weight: 400;
  --24-open-sans-normal-letter-spacing: 0px;
  --24-open-sans-normal-line-height: 32px;
  --24-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --24-open-sans-semibold-font-size: 24px;
  --24-open-sans-semibold-font-style: normal;
  --24-open-sans-semibold-font-weight: 600;
  --24-open-sans-semibold-letter-spacing: 0px;
  --24-open-sans-semibold-line-height: 32px;
  --32-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --32-open-sans-normal-font-size: 32px;
  --32-open-sans-normal-font-style: normal;
  --32-open-sans-normal-font-weight: 400;
  --32-open-sans-normal-letter-spacing: 0px;
  --32-open-sans-normal-line-height: 40px;
  --32-open-sans-semibold-font-family: "OpenSans-SemiBold", Helvetica;
  --32-open-sans-semibold-font-size: 32px;
  --32-open-sans-semibold-font-style: normal;
  --32-open-sans-semibold-font-weight: 600;
  --32-open-sans-semibold-letter-spacing: 0px;
  --32-open-sans-semibold-line-height: 40px;
  --40-open-sans-bold-font-family: "OpenSans-Bold", Helvetica;
  --40-open-sans-bold-font-size: 40px;
  --40-open-sans-bold-font-style: normal;
  --40-open-sans-bold-font-weight: 700;
  --40-open-sans-bold-letter-spacing: 0px;
  --40-open-sans-bold-line-height: 48px;
  --40-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --40-open-sans-normal-font-size: 40px;
  --40-open-sans-normal-font-style: normal;
  --40-open-sans-normal-font-weight: 400;
  --40-open-sans-normal-letter-spacing: 0px;
  --40-open-sans-normal-line-height: 48px;
  --48-open-sans-bold-font-family: "OpenSans-Bold", Helvetica;
  --48-open-sans-bold-font-size: 48px;
  --48-open-sans-bold-font-style: normal;
  --48-open-sans-bold-font-weight: 700;
  --48-open-sans-bold-letter-spacing: 0px;
  --48-open-sans-bold-line-height: 56px;
  --48-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --48-open-sans-normal-font-size: 48px;
  --48-open-sans-normal-font-style: normal;
  --48-open-sans-normal-font-weight: 400;
  --48-open-sans-normal-letter-spacing: 0px;
  --48-open-sans-normal-line-height: 56px;
  --60-open-sans-bold-font-family: "OpenSans-Bold", Helvetica;
  --60-open-sans-bold-font-size: 60px;
  --60-open-sans-bold-font-style: normal;
  --60-open-sans-bold-font-weight: 700;
  --60-open-sans-bold-letter-spacing: 0px;
  --60-open-sans-bold-line-height: 72px;
  --60-open-sans-normal-font-family: "OpenSans-Regular", Helvetica;
  --60-open-sans-normal-font-size: 60px;
  --60-open-sans-normal-font-style: normal;
  --60-open-sans-normal-font-weight: 400;
  --60-open-sans-normal-letter-spacing: 0px;
  --60-open-sans-normal-line-height: 72px;
  --basecommoncommon-2: rgba(203, 204, 207, 1);
  --basicwhite: rgba(255, 255, 255, 1);
  --companian-2: rgba(71, 100, 250, 1);
  --darkdark-1: rgba(233, 233, 234, 1);
  --darkdark-10: rgba(17, 17, 21, 1);
  --darkdark-2: rgba(203, 203, 205, 1);
  --darkdark-3: rgba(162, 162, 166, 1);
  --darkdark-4: rgba(118, 118, 124, 1);
  --darkdark-5: rgba(77, 77, 84, 1);
  --darkdark-6: rgba(38, 38, 47, 1);
  --darkdark-7: rgba(32, 32, 40, 1);
  --darkdark-8: rgba(27, 27, 33, 1);
  --darkdark-9: rgba(22, 22, 27, 1);
  --lookonlookon-primary: rgba(71, 121, 250, 1);
  --mon-h6-20-mon-s-font-family: "Montserrat-SemiBold", Helvetica;
  --mon-h6-20-mon-s-font-size: 20px;
  --mon-h6-20-mon-s-font-style: normal;
  --mon-h6-20-mon-s-font-weight: 600;
  --mon-h6-20-mon-s-letter-spacing: 0px;
  --mon-h6-20-mon-s-line-height: 28px;
  --statusnegative-1: rgba(252, 237, 239, 1);
  --statusnegative-10: rgba(99, 31, 41, 1);
  --statusnegative-2: rgba(247, 211, 216, 1);
  --statusnegative-3: rgba(240, 175, 184, 1);
  --statusnegative-4: rgba(234, 138, 152, 1);
  --statusnegative-5: rgba(227, 103, 121, 1);
  --statusnegative-6: rgba(221, 70, 91, 1);
  --statusnegative-7: rgba(188, 60, 77, 1);
  --statusnegative-8: rgba(157, 50, 65, 1);
  --statusnegative-9: rgba(126, 40, 52, 1);
  --statuspositive-1: rgba(244, 250, 237, 1);
  --statuspositive-10: rgba(65, 92, 32, 1);
  --statuspositive-2: rgba(229, 243, 211, 1);
  --statuspositive-3: rgba(208, 233, 176, 1);
  --statuspositive-4: rgba(186, 223, 139, 1);
  --statuspositive-5: rgba(165, 213, 104, 1);
  --statuspositive-6: rgba(145, 204, 71, 1);
  --statuspositive-7: rgba(123, 173, 60, 1);
  --statuspositive-8: rgba(103, 145, 50, 1);
  --statuspositive-9: rgba(83, 116, 40, 1);
  --statuswarning-1: rgba(251, 248, 236, 1);
  --statuswarning-10: rgba(99, 83, 28, 1);
  --statuswarning-2: rgba(246, 238, 209, 1);
  --statuswarning-3: rgba(240, 225, 172, 1);
  --statuswarning-4: rgba(232, 211, 134, 1);
  --statuswarning-5: rgba(225, 198, 98, 1);
  --statuswarning-6: rgba(219, 185, 63, 1);
  --statuswarning-7: rgba(186, 157, 54, 1);
  --statuswarning-8: rgba(155, 131, 45, 1);
  --statuswarning-9: rgba(125, 105, 36, 1);
  --supportsupport-1: rgba(245, 247, 249, 1);
  --supportsupport-10: rgba(70, 77, 90, 1);
  --supportsupport-2: rgba(231, 235, 242, 1);
  --supportsupport-3: rgba(212, 219, 231, 1);
  --supportsupport-4: rgba(192, 202, 220, 1);
  --supportsupport-5: rgba(173, 186, 209, 1);
  --supportsupport-6: rgba(155, 171, 199, 1);
  --supportsupport-7: rgba(132, 145, 169, 1);
  --supportsupport-8: rgba(110, 121, 141, 1);
  --supportsupport-9: rgba(88, 97, 113, 1);
  --variable-collection-companians-solid: rgba(255, 73, 95, 1);
  --white: rgba(255, 255, 255, 1);
}
